// 获取缓存
export function getLocalStorage(key) {
  return localStorage.getItem(key)
}
// 设置缓存
export function setLocalStorage(key, value) {
  return localStorage.setItem(key, value)
}
// 删除缓存
export function removeLocalStorage(key) {
  return localStorage.removeItem(key)
}
// 清除缓存
export function clearLocalStorage() {
  return localStorage.clear()
}
// 获取url参数
export function getQueryVariable(variable) {
  var query = window.location.search.substring(1);
  var vars = query.split("&");
  for (var i = 0; i < vars.length; i++) {
    var pair = vars[i].split("=");
    if (pair[0] == variable) {
      return pair[1];
    }
  }
  return (false);
}

// 姓名脱敏处理
export function noPassByName(str) {
  if (str) {
    if (str.length == 2) {
      return str.substring(0, 1) + '*' //截取name 字符串截取第一个字符，
    } else if (str.length == 3) {
      return str.substring(0, 1) + "*" + str.substring(2, 3) //截取第一个和第三个字符
    } else if (str.length > 3) {
      return str.substring(0, 1) + "*" + '*' + str.substring(3, str.length) //截取第一个和大于第4个字符
    }
  } else {
    return "";
  }
}
// 验证手机号码规则
export function checkMobile(rule, value, callback) {
  const reg = /^1[3|4|5|7|8][0-9]\d{8}$/;
  if (!value) {
    return callback(new Error('手机号不能为空'));
  } else if (!reg.test(value)) {
    return callback(new Error('请输入正确的手机号'));
  } else {
    return callback();
  }
}
// s手机号脱敏
export function phoneDesensitization(phone, char) {
  return phone.replace(/(\d{3})\d*(\d{4})/, `$1${new Array(5).join(char)}$2`)
}

// 检查密码
export function validatePassword(rule, value, callback, password) {
  if (value === '') {
    callback(new Error('请再次输入密码'));
  } else if (value !== password) {
    callback(new Error('两次输入密码不一致!'));
  } else {
    callback();
  }
}

// html 转义
export function htmlToText(html) {
  let div = document.createElement('div');
  div.innerHTML = html;
  return div.textContent;
}
// html 反转义
export function textToHtml(text) {
  let div = document.createElement('div');
  div.textContent = text;
  return div.innerHTML;
}
// 时间格式化 YYYY-MM-DD hh:mm:ss YYYY/MM/DD hh:mm:ss,
export const formateDate = (date, formater) => {
  if (typeof(date) !== 'number' && typeof(date) !== 'object' || !date) {
		return "-- : --";
	}
  var nowDate = new Date(date);
  var year = nowDate.getFullYear();
  var month = nowDate.getMonth() + 1;
  month = month < 10 ? "0" + month : month;
  var day = nowDate.getDate();
  day = day < 10 ? "0" + day : day;
  var hour = nowDate.getHours();
  hour = hour < 10 ? "0" + hour : hour;
  var minute = nowDate.getMinutes();
  minute = minute < 10 ? "0" + minute : minute;
  var second = nowDate.getSeconds();
  second = second < 10 ? "0" + second : second;
  var _after = year + '-' + month + '-' + day;
  if (formater) {
    _after = formater.replace('YYYY', year).replace('MM', month).replace('DD', day).replace('hh', hour).replace('mm', minute).replace('ss', second);
  }
  return _after;
};
// 获取前几天的时间
export function prevDate(day) {
  var today = new Date();
  var targetdayMilliseconds = today.getTime() + 1000 * 60 * 60 * 24 * day;
  today.setTime(targetdayMilliseconds); //注意，这行是关键代码
  return today
}

// 多维数组扁平化
export const flattenArr = (arr) => {
  return [].concat(...arr.map(x => Array.isArray(x) ? flattenArr(x) : x))
}

// 防抖
export const debounce = (fn, delay) => {
  var timer = null;
  return function () {
    var context = this;
    var args = arguments;
    if (timer) clearTimeout(timer);
    timer = setTimeout(function () {
      fn.apply(context, args);
    }, delay);
  }
}
//节流
export const throttle = (fn, delay) => {
  var timer = null;
  return function () {
    var context = this;
    var args = arguments;
    if (!timer) {
      timer = setTimeout(function () {
        fn.apply(context, args)
        timer = null;
      }, delay)
    }
  }
}

// 手机正则
export const checkPhone = (phone) => {
	if (!(/^1[3-9]\d{9}$/.test(phone))) {
		return false;
	}
	return true
}