import Vue from 'vue'
import App from './App.vue'
import router from './router';
import store from './store';
import 'amfe-flexible';
import 'style/reset.css'
import { getQueryVariable } from './common/utils';

// 使用 VConsole
import VConsole from 'vconsole';
const ua = navigator.userAgent 
if (getQueryVariable('debug') === 'true') {
  new VConsole({ theme: 'dark' });
  console.log(ua)
}

Vue.config.productionTip = false
// 路由重定向
router.beforeEach( async(to, from, next) => {
  // console.log(to, from)
  const title = to.meta?.title;
  document.title = title;
  next()
})

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
