import Vue from "vue"
import Vuex from "vuex"
import {
  getLocalStorage,
  setLocalStorage,
  clearLocalStorage
} from "@/common/utils";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    userInfo:  new Function("return " + getLocalStorage("userInfo"))()
  },
  actions: {
    setUserInfo({
      commit
    }, value) {
      commit('setUserInfo', value);
    },
    logout({
      commit
    }) {
      commit('logout');
    }
  },
  mutations: {
    setUserInfo(state, value) {
      state.userInfo = value;
      setLocalStorage('userInfo', JSON.stringify(value))
    },
    logout(state) {
      state.userInfo = null;
      clearLocalStorage()
    },
  }
})