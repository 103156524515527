<template>
  <div id="app">
    <keep-alive>
      <router-view v-if="$route.meta.keepAlive"></router-view>
    </keep-alive>
    <router-view v-if="!$route.meta.keepAlive"></router-view>
  </div>
</template>

<script>
// 登录已迁移至request.js
// import { mapState, mapActions } from "vuex";
// import { refreshToken } from "@/api/login.js";
export default {
  name: "App",
  data() {
    return {};
  },
  computed: {
    // ...mapState({
    //   userInfo: (state) => state.userInfo,
    // }),
  },
  mounted() { 
    // if (this.userInfo) {
    //   this.refreshToken(this.userInfo.refresh_token);
    // } else {
    //   this.$router.push("/login");
    // }
  },
  methods: {
    // ...mapActions(["setUserInfo","logout"]),
    // async refreshToken(refresh_token) {
    //   try {
    //     const userInfo = await refreshToken(refresh_token);
    //     this.setUserInfo(userInfo)
    //   } catch (e) {
    //     this.logout()
    //     this.$router.push("/login");
    //   }
    // },
  },
};
</script>

<style lang="scss">
#app {
  width: 100%;
  min-height: 100vh;
  background: #f6f6f6;
}
</style>
