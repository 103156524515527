import Vue from 'vue';
import Router from 'vue-router';
Vue.use(Router);
export default new Router({
  routes: [
    {
      path: '/',
      redirect: '/index'
    },
    {
      path: '/index',
      name: 'index',
      component: () => import( '@/page/home/index'),
      meta:{
        title: '青原掌上大厅'
      }
    },
    {
      path: '/appoint',
      name: 'appoint',
      component: () => import( '@/page/appoint/index'),
      meta:{
        title: '预约办理'
      }
    },
    {
      path: '/my-appoint',
      name: 'my-appoint',
      component: () => import( '@/page/myAppoint/index'),
      meta:{
        title: '我的预约'
      }
    },
    {
      path: '/query',
      name: 'query',
      component: () => import( '@/page/query/index'),
      meta:{
        title: '出件查询'
      }
    },
    {
      path: '/assets',
      name: 'assets',
      component: () => import( '@/page/assets/index'),
      meta:{
        title: '资产管理'
      }
    },
    {
      path: '/qrcode',
      name: 'qrcode',
      component: () => import( '@/page/qrcode/index'),
      meta:{
        title: '资产管理'
      }
    },
    {
      path: '/apply',
      name: 'apply',
      component: () => import( '@/page/apply/index'),
      meta:{
        title: '资产管理'
      }
    },
    {
      path: '/notify',
      name: 'notify',
      component: () => import( '@/page/notify/index'),
      meta:{
        title: '消息通知'
      }
    },
    {
      path: '/login',
      name: 'login',
      component: () => import( '@/page/login/index'),
      meta:{
        title: '授权登录'
      }
    },
    {
      path: '*',
      redirect:'/'
    }
  ],
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return {
        x: 0,
        y: 0
      }
    }
  }
})